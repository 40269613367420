import React, { Component } from "react";

export default class CloudInfraImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="813.61811" height="712.2585"
    viewBox="0 0 813.61811 712.2585" 
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
    <ellipse cx="871.07015" cy="568.69205" rx="159.58694" ry="99.05396"
        transform="translate(-349.5705 637.67383) rotate(-42.14571)" fill="#f2f2f2" />
    <path
        d="M747.4036,804.0366c-8.81255-18.20463-11.93327-38.71657-10.399-58.79967A141.30621,141.30621,0,0,1,754.2765,688.398a189.38885,189.38885,0,0,1,35.49459-45.52037c14.80021-14.17745,31.48931-26.21325,49.0486-36.73214,18.54208-11.10763,38.02552-20.54709,57.7357-29.37895q3.68454-1.651,7.38047-3.27634c1.617-.712.21837-3.08346-1.38874-2.37584-20.09581,8.84829-40.02233,18.173-59.07737,29.11413-18.20417,10.45259-35.62033,22.41-51.18028,36.54023a198.43861,198.43861,0,0,0-37.48163,45.14356A147.57374,147.57374,0,0,0,734.93762,738.521c-2.5697,20.17315-.583,41.15145,7.06481,60.07948q1.39889,3.46221,3.02533,6.82486c.77134,1.59339,3.14481.19977,2.37584-1.38874Z"
        transform="translate(-193.19094 -93.87075)" fill="#e6e6e6" />
    <path
        d="M823.30067,613.162a166.25409,166.25409,0,0,1,3.356-44.75714,168.99409,168.99409,0,0,1,15.09216-42.29471,166.19982,166.19982,0,0,1,13.2957-21.52457c1.04373-1.43314-1.34249-2.80762-2.37584-1.38873a168.917,168.917,0,0,0-21.4982,40.00235,171.78051,171.78051,0,0,0-10.19852,44.278,166.132,166.132,0,0,0-.42285,25.6848c.10716,1.75928,2.85941,1.77148,2.7515,0Z"
        transform="translate(-193.19094 -93.87075)" fill="#e6e6e6" />
    <ellipse cx="442.38404" cy="495.07956" rx="207.71932" ry="128.92923"
        transform="translate(-411.01592 330.98442) rotate(-42.14571)" fill="#f2f2f2" />
    <path
        d="M281.41893,801.40545c-11.47048-23.69526-15.53243-50.39373-13.53535-76.534,1.98443-25.97458,10.00572-51.17167,22.48116-73.98189C302.446,628.8,318.41392,609.027,336.5647,591.64c19.264-18.45345,40.98668-34.11932,63.842-47.81078,24.13448-14.45776,49.49424-26.74421,75.14913-38.23981q4.79581-2.14894,9.60646-4.26451c2.10472-.92672.28424-4.01344-1.80758-3.0924-26.15683,11.517-52.09331,23.65406-76.89547,37.89512-23.69465,13.60516-46.36362,29.169-66.61655,47.561-18.893,17.157-35.73071,36.78388-48.78632,58.75913A192.083,192.083,0,0,0,265.19313,716.13c-3.34474,26.25749-.75884,53.563,9.1956,78.19981q1.82079,4.50642,3.93779,8.88327c1.004,2.074,4.0933.26,3.09241-1.80758Z"
        transform="translate(-193.19094 -93.87075)" fill="#e6e6e6" />
    <path
        d="M380.207,552.96187a216.39748,216.39748,0,0,1,4.36826-58.25615,219.96338,219.96338,0,0,1,19.644-55.051,216.32539,216.32539,0,0,1,17.30575-28.01651c1.35853-1.86538-1.74738-3.65442-3.0924-1.80758a219.86282,219.86282,0,0,0-27.98219,52.0673,223.59054,223.59054,0,0,0-13.27445,57.6325,216.23939,216.23939,0,0,0-.55039,33.43149c.13949,2.28989,3.72182,2.30577,3.58137,0Z"
        transform="translate(-193.19094 -93.87075)" fill="#e6e6e6" />
    <polygon points="501.384 26 461.414 91.898 501.298 115.501 501.384 26" fill="#8c8c8c" />
    <polygon points="461.549 92.033 501.298 73.916 501.249 115.529 461.549 92.033" fill="#383838" />
    <polygon points="501.211 26 541.182 91.898 501.298 115.501 501.211 26" fill="#333" />
    <polygon points="541.047 92.033 501.298 73.916 501.346 115.529 541.047 92.033" fill="#141414" />
    <polygon points="461.549 99.595 501.249 155.5 501.249 123.091 461.549 99.595" fill="#8c8c8c" />
    <polygon points="540.95 99.595 501.249 155.5 501.249 123.091 540.95 99.595" fill="#333" />
    <path
        d="M694.80906,296.87075c-21.47217,0-42.86426-14.75928-60.23609-41.55908-15.2749-23.56494-24.76391-52.85547-24.76391-76.44092a85,85,0,1,1,170,0c0,23.58545-9.489,52.876-24.76392,76.44092C737.67331,282.11147,716.28122,296.87075,694.80906,296.87075Zm0-201a83.09384,83.09384,0,0,0-83,83c0,45.80762,37.18383,116,83,116s83-70.19238,83-116A83.09385,83.09385,0,0,0,694.80906,95.87075Z"
        transform="translate(-193.19094 -93.87075)" fill="#3f3d56" />
    <path
        d="M702.82248,314.87075H686.79563a4.99984,4.99984,0,0,1-4.88086-6.08447l3.092-13.91553H704.6113l3.092,13.91553a4.99984,4.99984,0,0,1-4.88086,6.08447Zm-16.21118-18-2.74438,12.34912a3.0002,3.0002,0,0,0,2.92871,3.65088h16.02685a3.0002,3.0002,0,0,0,2.92871-3.65088l-2.74438-12.34912Z"
        transform="translate(-193.19094 -93.87075)" fill="#3f3d56" />
    <path
        d="M655.24913,118.73207a58.4403,58.4403,0,0,0-26.39551,35.90807,56.1951,56.1951,0,0,0-1.62463,12.73165,1.5009,1.5009,0,0,0,3,0,55.38506,55.38506,0,0,1,16.821-38.60748,52.63215,52.63215,0,0,1,9.71327-7.44182,1.50112,1.50112,0,0,0-1.51416-2.59042Z"
        transform="translate(-193.19094 -93.87075)" fill="#3f3d56" />
    <path
        d="M680.91654,298.75515c18.12262-.92765,38.74522-1.422,52.869,11.94557,5.89108,5.57568,9.60778,13.04755,9.38366,21.26143-.24389,8.9385-4.78918,17.31157-10.42206,24.02662-12.12594,14.45556-29.90953,23.49395-46.96391,30.77883-18.126,7.74265-37.00889,13.789-54.42431,23.13207C616.068,418.103,601.81494,429.176,592.57768,444.093a62.48306,62.48306,0,0,0-8.8561,23.75627,67.38471,67.38471,0,0,0,1.26309,25.37213c4.06791,17.01383,14.08316,32.05049,27.42863,43.22353,13.50543,11.307,29.86609,18.83645,46.81635,23.335,20.12215,5.3403,41.08822,6.549,61.821,6.66982,21.41283.12473,42.84346-.923,64.24146.27721,5.20489.29194,10.40073.72292,15.57931,1.323,1.91429.22181,1.8984-2.78,0-3-21.37675-2.477-42.9212-1.90954-64.39379-1.66672-21.0512.23806-42.28077.16475-63.0709-3.552-17.92524-3.20462-35.50032-9.21059-50.70307-19.397-14.56244-9.75735-26.43061-23.43911-32.43634-40.041-5.8664-16.21681-5.805-34.16408,2.05032-49.71034,7.8653-15.5661,21.585-27.34159,36.49353-35.93455,17.20467-9.91642,36.14888-16.12221,54.42574-23.6834C700.5,383.92305,718.157,375.407,731.41794,361.90558c6.14112-6.25251,11.40149-13.86221,13.65264-22.42406a30.33265,30.33265,0,0,0-3.403-23.90111,40.57685,40.57685,0,0,0-17.84641-15.04457c-8.80276-3.96638-18.56975-5.1098-28.14049-5.20641-4.92495-.04971-9.847.174-14.76417.42572-1.92106.09833-1.93276,3.09893,0,3Z"
        transform="translate(-193.19094 -93.87075)" fill="#3f3d56" />
    <polygon points="395.471 691.743 383.447 694.137 368.491 648.9 386.237 645.366 395.471 691.743" fill="#ffb8b8" />
    <path
        d="M594.04949,796.65863l-38.76938,7.71988-.09766-.49037a15.386,15.386,0,0,1,12.08478-18.09524l.001-.00019,23.67894-4.71494Z"
        transform="translate(-193.19094 -93.87075)" fill="#2f2e41" />
    <polygon points="510.334 698.624 498.074 698.623 484.242 651.335 502.336 651.336 510.334 698.624" fill="#ffb8b8" />
    <path d="M706.65124,804.37851l-39.53052-.00147v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
        transform="translate(-193.19094 -93.87075)" fill="#2f2e41" />
    <path
        d="M674.25951,657.43311a10.74267,10.74267,0,0,0-3.2851-16.14173l-24.49211-94.7298-21.30326,9.5895L655.95269,646.252a10.80091,10.80091,0,0,0,18.30682,11.18111Z"
        transform="translate(-193.19094 -93.87075)" fill="#ffb8b8" />
    <path
        d="M652.21231,562.43471l-23.6765,5.59155a4.81688,4.81688,0,0,1-5.90657-4.27943L620.574,539.58a13.37737,13.37737,0,0,1,26.05627-6.073l8.95,22.458a4.81687,4.81687,0,0,1-3.368,6.46975Z"
        transform="translate(-193.19094 -93.87075)" fill="#6c63ff" />
    <path
        d="M571.44264,777.03826a4.48014,4.48014,0,0,1-4.394-3.584l-11.92871-57.25781a27.93154,27.93154,0,0,1,4.07934-21.0625l37.58082-56.3711V596.51482l43.62768-9.83105.18848.15332c22.63159,18.48047,21.32959,41.2627,21.19043,43.02344L704.66335,769.089a4.5005,4.5005,0,0,1-4.30054,5.8252H687.4883a4.47879,4.47879,0,0,1-4.17847-2.8291l-46.21094-110.374-54.38647,44.75977a3.50324,3.50324,0,0,0-1.26416,2.98633l5.01465,61.54492a4.47674,4.47674,0,0,1-3.98828,4.83789l-10.52369,1.16992A4.703,4.703,0,0,1,571.44264,777.03826Z"
        transform="translate(-193.19094 -93.87075)" fill="#2f2e41" />
    <circle cx="434.02217" cy="389.39388" r="24.56103" fill="#ffb8b8" />
    <path
        d="M599.104,601.20135a4.51962,4.51962,0,0,1-4.17847-2.793c-8.22729-20.2002-8.09106-45.71387-4.48291-60.87109a28.18,28.18,0,0,1,28.96-21.57227h.00025a28.22207,28.22207,0,0,1,26.41821,32.292c-1.92334,13.03418-2.70434,31.39258-2.95093,38.67969a4.51233,4.51233,0,0,1-3.39453,4.20117l-39.27392,9.92676A4.45739,4.45739,0,0,1,599.104,601.20135Z"
        transform="translate(-193.19094 -93.87075)" fill="#6c63ff" />
    <path
        d="M599.55548,454.57527a10.52612,10.52612,0,0,1-1.57752.50864l-17.29563,46.47957,7.66314,9.29682-12.519,13.39885L560.661,509.11933a8,8,0,0,1-1.36508-9.5035l27.09542-49.48971a10.49709,10.49709,0,1,1,13.16417,4.44915Z"
        transform="translate(-193.19094 -93.87075)" fill="#ffb8b8" />
    <path
        d="M571.40262,522.92848l13.7953-20.03826a4.81688,4.81688,0,0,1,7.25066-.79314l17.74634,16.53267a13.37737,13.37737,0,0,1-15.236,21.99263l-21.70957-10.63765a4.81688,4.81688,0,0,1-1.84676-7.05625Z"
        transform="translate(-193.19094 -93.87075)" fill="#6c63ff" />
    <path
        d="M625.79924,511.41424c-1.76994-1.96266-.73546-5.33378.90169-7.42793s3.84921-3.87329,4.49485-6.52928c1.09945-4.5228-3.00267-8.64692-7.09478-10.10847s-8.67717-1.57462-12.28071-4.13769c-5.46235-3.88517-6.7071-12.73915-3.56877-18.95711s9.699-9.78377,16.2104-10.31669,12.98164,1.5841,18.94145,4.48142c5.764,2.80212,11.32386,6.45264,15.37427,11.714s6.43372,12.33207,5.26333,19.07085c-1.42942,8.23024-7.87831,14.58339-15.043,17.68474s-15.03915,3.51025-22.74455,3.88121Z"
        transform="translate(-193.19094 -93.87075)" fill="#2f2e41" />
    <path
        d="M653.14281,464.522a41.87619,41.87619,0,0,1,57.087-1.71618c13.29314,11.66691,18.36112,30.88094,32.31461,41.74946,12.56243,9.785,31.42544,10.51387,44.70545,1.72737-6.06394,7.0836-12.23316,14.2617-19.99628,19.42724s-17.46469,8.149-26.52227,5.9333c-10.63274-2.601-18.36974-11.65468-24.37808-20.80454s-11.30381-19.1734-20.01561-25.801a37.47157,37.47157,0,0,0-22.949-7.57985c-5.0679.06249-10.24028,1.1504-15.1373-.156s-9.39524-6.30079-7.7642-11.09945Z"
        transform="translate(-193.19094 -93.87075)" fill="#2f2e41" />
    <path d="M880.19094,805.87075h-686a1,1,0,1,1,0-2h686a1,1,0,0,1,0,2Z" transform="translate(-193.19094 -93.87075)"
        fill="#3f3d56" />
</svg>
    );
  }
}
