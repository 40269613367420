import React, { Component } from "react";

export default class ProjectsImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="790.85551" height="705.6377"
    viewBox="0 0 790.85551 705.6377" 
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
    <path d="M375.53652,314.45654a8,8,0,0,0,0,16H476.09658v-3.49a14.97671,14.97671,0,0,1,6.73974-12.51Z"
        transform="translate(-204.57225 -97.18115)" fill="#e6e6e6" />
    <path d="M467.04134,296.45654h-34a8,8,0,1,1,0-16h34a8,8,0,0,1,0,16Z" transform="translate(-204.57225 -97.18115)"
        fill="#e6e6e6" />
    <path d="M390.78585,239.45654h-132a8,8,0,1,1,0-16h132a8,8,0,0,1,0,16Z" transform="translate(-204.57225 -97.18115)"
        fill="#e6e6e6" />
    <path d="M333.28585,205.45654h-17a8,8,0,1,1,0-16h17a8,8,0,0,1,0,16Z" transform="translate(-204.57225 -97.18115)"
        fill="#e6e6e6" />
    <path
        d="M475.09243,475.35107h-124.438a17.01916,17.01916,0,0,1-17-17V261.51123a17.01917,17.01917,0,0,1,17-17H549.42812a17.01917,17.01917,0,0,1,17,17v48.7876h-2v-48.7876a15.017,15.017,0,0,0-15-15H350.65444a15.017,15.017,0,0,0-15,15V458.35107a15.017,15.017,0,0,0,15,15h124.438Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path
        d="M334.65444,357.51514H243.94509a17.01917,17.01917,0,0,1-17-17V180.45654a17.01917,17.01917,0,0,1,17-17H405.62648a17.01917,17.01917,0,0,1,17,17v65.05469h-2V180.45654a15.017,15.017,0,0,0-15-15H243.94509a15.017,15.017,0,0,0-15,15v160.0586a15.017,15.017,0,0,0,15,15h90.70935Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path d="M739.53652,314.45654a14.97671,14.97671,0,0,1,6.73974,12.51v3.49H846.83632a8,8,0,0,0,0-16Z"
        transform="translate(-204.57225 -97.18115)" fill="#e6e6e6" />
    <path
        d="M747.3315,288.45654a8.00917,8.00917,0,0,1,8-8h34a8,8,0,1,1,0,16h-34A8.00916,8.00916,0,0,1,747.3315,288.45654Z"
        transform="translate(-204.57225 -97.18115)" fill="#e6e6e6" />
    <path
        d="M823.587,231.45654a8.00917,8.00917,0,0,1,8-8h132a8,8,0,1,1,0,16h-132A8.00916,8.00916,0,0,1,823.587,231.45654Z"
        transform="translate(-204.57225 -97.18115)" fill="#e6e6e6" />
    <path
        d="M881.087,197.45654a8.00917,8.00917,0,0,1,8-8h17a8,8,0,1,1,0,16h-17A8.00916,8.00916,0,0,1,881.087,197.45654Z"
        transform="translate(-204.57225 -97.18115)" fill="#e6e6e6" />
    <path
        d="M747.28041,475.35107h124.438a17.01916,17.01916,0,0,0,17-17V261.51123a17.01917,17.01917,0,0,0-17-17H672.94472a17.01917,17.01917,0,0,0-17,17v48.7876h2v-48.7876a15.017,15.017,0,0,1,15-15H871.7184a15.017,15.017,0,0,1,15,15V458.35107a15.017,15.017,0,0,1-15,15h-124.438Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path
        d="M887.7184,357.51514h90.70935a17.01917,17.01917,0,0,0,17-17V180.45654a17.01917,17.01917,0,0,0-17-17H816.74636a17.01917,17.01917,0,0,0-17,17v65.05469h2V180.45654a15.017,15.017,0,0,1,15-15H978.42775a15.017,15.017,0,0,1,15,15v160.0586a15.017,15.017,0,0,1-15,15H887.7184Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path
        d="M730.85634,309.96655H491.09658a17.03389,17.03389,0,0,0-17,17v237.49a17.01782,17.01782,0,0,0,17,17H730.85634a17.01783,17.01783,0,0,0,17-17v-237.49A17.01784,17.01784,0,0,0,730.85634,309.96655Zm15,254.49a15.01828,15.01828,0,0,1-15,15H491.09658a15.01828,15.01828,0,0,1-15-15v-237.49a15.02322,15.02322,0,0,1,15-15H730.85634a15.01828,15.01828,0,0,1,15,15Z"
        transform="translate(-204.57225 -97.18115)" fill="#3f3d56" />
    <path d="M711.97561,402.45654h-202a8,8,0,1,1,0-16h202a8,8,0,0,1,0,16Z" transform="translate(-204.57225 -97.18115)"
        fill="#ccc" />
    <path d="M654.47561,368.45654h-87a8,8,0,1,1,0-16h87a8,8,0,0,1,0,16Z" transform="translate(-204.57225 -97.18115)"
        fill="#6c63ff" />
    <path d="M596.47561,450.45654h-87a8,8,0,1,1,0-16h87a8,8,0,0,1,0,16Z" transform="translate(-204.57225 -97.18115)"
        fill="#ccc" />
    <path d="M596.47561,485.45654h-87a8,8,0,1,1,0-16h87a8,8,0,0,1,0,16Z" transform="translate(-204.57225 -97.18115)"
        fill="#ccc" />
    <path d="M596.47561,520.45654h-87a8,8,0,1,1,0-16h87a8,8,0,0,1,0,16Z" transform="translate(-204.57225 -97.18115)"
        fill="#ccc" />
    <circle id="fc69f6bd-6aec-467a-b158-496b315f473e" data-name="Ellipse 44" cx="500.40945" cy="345.20452" r="14.92913"
        fill="#6c63ff" />
    <path id="f31c0d86-d70b-4e63-97a7-2fc94b4d7c12-5348" data-name="Path 395"
        d="M703.5023,448.0807a1.54842,1.54842,0,0,1-.93151-.30958l-.01666-.0125-3.50855-2.68392a1.55917,1.55917,0,0,1,1.89726-2.47477l2.27256,1.7427,5.37016-7.006a1.55853,1.55853,0,0,1,2.18512-.28862l.00045.00034-.03333.04628.03423-.04628a1.56039,1.56039,0,0,1,.28829,2.18555l-6.31649,8.23688a1.55948,1.55948,0,0,1-1.24016.608Z"
        transform="translate(-204.57225 -97.18115)" fill="#fff" />
    <path
        d="M324.78579,143.59033a23.20459,23.20459,0,1,1,23.20471-23.20459A23.231,23.231,0,0,1,324.78579,143.59033Zm0-44.40918a21.20459,21.20459,0,1,0,21.20471,21.20459A21.22862,21.22862,0,0,0,324.78579,99.18115Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path id="b565f894-1e41-4a11-b880-46bff78dfbdf-5349" data-name="Path 395"
        d="M322.58542,128.85612a2.303,2.303,0,0,1-1.38548-.46045l-.02478-.01859-5.21841-3.99191a2.319,2.319,0,1,1,2.82188-3.68082l3.38007,2.592,7.98727-10.42029a2.318,2.318,0,0,1,3.25-.42928l.00067.00051-.04957.06883.05091-.06883a2.32082,2.32082,0,0,1,.42878,3.25065L324.432,127.949a2.31945,2.31945,0,0,1-1.84454.90437Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path
        d="M471.98159,224.59033a23.20459,23.20459,0,1,1,23.20483-23.20459A23.23088,23.23088,0,0,1,471.98159,224.59033Zm0-44.40918a21.20459,21.20459,0,1,0,21.20483,21.20459A21.22859,21.22859,0,0,0,471.98159,180.18115Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path id="a1067fe6-b388-4736-89af-b6090c7ddfc7-5350" data-name="Path 395"
        d="M469.78133,209.85612a2.303,2.303,0,0,1-1.38548-.46045l-.02478-.01859-5.21841-3.99191a2.319,2.319,0,1,1,2.82188-3.68082l3.38007,2.592,7.98727-10.42029a2.318,2.318,0,0,1,3.25-.42928l.00067.00051-.04957.06883.05091-.06883a2.32082,2.32082,0,0,1,.42878,3.25065L471.6279,208.949a2.31945,2.31945,0,0,1-1.84454.90437Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <circle id="a6907563-545c-4a91-99f2-6d7f4e57ba01" data-name="Ellipse 44" cx="500.40945" cy="380.20452" r="14.92913"
        fill="#6c63ff" />
    <path id="f071b9ad-7606-418c-8652-2e0910ae4e99-5351" data-name="Path 395"
        d="M703.5023,483.0807a1.54842,1.54842,0,0,1-.93151-.30958l-.01666-.0125-3.50855-2.68392a1.55917,1.55917,0,0,1,1.89726-2.47477l2.27256,1.7427,5.37016-7.006a1.55853,1.55853,0,0,1,2.18512-.28862l.00045.00034-.03333.04628.03423-.04628a1.56039,1.56039,0,0,1,.28829,2.18555l-6.31649,8.23688a1.55948,1.55948,0,0,1-1.24016.608Z"
        transform="translate(-204.57225 -97.18115)" fill="#fff" />
    <circle id="a9a7744d-510c-41c6-a641-cf6a8a976d00" data-name="Ellipse 44" cx="500.40945" cy="415.27539" r="14.92913"
        fill="#6c63ff" />
    <path id="ee4f98ac-d41c-4601-9f0c-7c3b6a7a76ac-5352" data-name="Path 395"
        d="M703.5023,518.15157a1.54842,1.54842,0,0,1-.93151-.30958l-.01666-.0125-3.50855-2.68393a1.55917,1.55917,0,0,1,1.89726-2.47476l2.27256,1.7427,5.37016-7.006a1.55852,1.55852,0,0,1,2.18512-.28862l.00045.00033-.03333.04628.03423-.04628a1.56039,1.56039,0,0,1,.28829,2.18555l-6.31649,8.23689a1.55948,1.55948,0,0,1-1.24016.60805Z"
        transform="translate(-204.57225 -97.18115)" fill="#fff" />
    <path
        d="M610.9977,289.59033c-15.74927-.26855-22.90625-12.21777-22.90552-23.20654.001-10.98877,7.15869-22.93652,22.87256-23.20264h.03394c15.71582.26807,22.873,12.21729,22.87231,23.20606S626.71205,289.32422,610.9977,289.59033Zm-.01587-44.40918c-14.352.251-20.88892,11.165-20.88965,21.20264-.00073,10.042,6.541,20.96142,20.90576,21.20654,14.32959-.24316,20.87232-11.16162,20.87305-21.20312C631.87148,256.34961,625.33388,245.43555,610.98183,245.18115Z"
        transform="translate(-204.57225 -97.18115)" fill="#6c63ff" />
    <path
        d="M616.1897,267.64593c4.0141,6.97343-7.00451,9.69688-11.22,5.49609l1.05591-2.25634c6.85387,5.92038,12.89281-4.33445,2.42407-3.35987v-2.47216c9.58671.77313,4.20182-8.87658-2.18408-3.168l-1.032-2.25586c5.20456-5.40159,16.88873,1.1299,8.64014,6.52783A4.11165,4.11165,0,0,1,616.1897,267.64593Z"
        transform="translate(-204.57225 -97.18115)" fill="#6c63ff" />
    <path
        d="M751.98159,224.59033a23.20459,23.20459,0,1,1,23.20483-23.20459A23.23088,23.23088,0,0,1,751.98159,224.59033Zm0-44.40918a21.20459,21.20459,0,1,0,21.20483,21.20459A21.22859,21.22859,0,0,0,751.98159,180.18115Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path
        d="M758.42567,206.59368h-2.42408v3.33594h-3.02392v-3.33594h-7.43994v-2.30419l7.99194-11.44776h2.47192v11.32813h2.42408Zm-5.448-2.42382v-6.5044l-4.512,6.5044Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path
        d="M897.58705,143.59033a23.20459,23.20459,0,1,1,23.20484-23.20459A23.23109,23.23109,0,0,1,897.58705,143.59033Zm0-44.40918a21.20459,21.20459,0,1,0,21.20484,21.20459A21.22864,21.22864,0,0,0,897.58705,99.18115Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path
        d="M901.04311,118.59784a4.76509,4.76509,0,0,1,1.896,1.91992,5.8622,5.8622,0,0,1,.67187,2.84375,5.66338,5.66338,0,0,1-.76807,2.96435,5.14271,5.14271,0,0,1-2.208,1.9917,7.46881,7.46881,0,0,1-3.33594.708,9.61289,9.61289,0,0,1-3.168-.54,7.35773,7.35773,0,0,1-2.56787-1.45166l1.032-2.25635a7.77012,7.77012,0,0,0,4.752,1.77637,3.45143,3.45143,0,0,0,2.46-.8042,3.44028,3.44028,0,0,0-.02393-4.56006,3.21412,3.21412,0,0,0-2.34009-.852,4.8557,4.8557,0,0,0-1.896.37207,4.48207,4.48207,0,0,0-1.56006,1.0918h-1.87183V111.74578H902.819v2.39991h-7.67993v4.65576a5.30553,5.30553,0,0,1,3.072-.8877A5.70865,5.70865,0,0,1,901.04311,118.59784Z"
        transform="translate(-204.57225 -97.18115)" fill="#ccc" />
    <path
        d="M352.76658,618.03129a10.05576,10.05576,0,0,1,6.68-13.89722l3.03148-35.606,14.57923,11.50129-5.1139,32.04882a10.11027,10.11027,0,0,1-19.17685,5.95314Z"
        transform="translate(-204.57225 -97.18115)" fill="#ffb8b8" />
    <path
        d="M362.143,600.39705a4.49721,4.49721,0,0,1-3.90353-4.27067l-1.68789-40.40688a89.09911,89.09911,0,0,1,8.60967-42.09305l14.96788-31.36456a13.43048,13.43048,0,0,1,10.08711-7.0092,12.88057,12.88057,0,0,1,11.23477,3.98922,16.88976,16.88976,0,0,1,1.9231,2.48163,12.92189,12.92189,0,0,1,.68213,12.30411l-22.95137,62.69962-1.7658,40.84776a4.50058,4.50058,0,0,1-5.02847,4.274l-12.10806-1.44407Z"
        transform="translate(-204.57225 -97.18115)" fill="#6c63ff" />
    <polygon points="114.63 679.762 125.856 684.69 150.204 643.734 133.636 636.462 114.63 679.762" fill="#ffb8b8" />
    <path
        d="M317.94754,772.02123l22.10781,9.704.0009.00039a15.38605,15.38605,0,0,1,7.904,20.27329l-.201.45782-36.197-15.88844Z"
        transform="translate(-204.57225 -97.18115)" fill="#2f2e41" />
    <polygon points="265.342 693.391 277.602 693.391 283.434 646.103 265.34 646.104 265.342 693.391" fill="#ffb8b8" />
    <path d="M466.78716,786.57l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38648,15.38623v.5l-39.53052.00146Z"
        transform="translate(-204.57225 -97.18115)" fill="#2f2e41" />
    <path
        d="M346.43225,777.12817a4.52688,4.52688,0,0,1-1.38868-.21972L324.256,770.22681a4.51916,4.51916,0,0,1-2.92652-5.59961l68.9353-219.51563,51.63477-10.125.18164.2959c34.13672,55.54785,45.53174,140.40137,52.76025,230.30762a4.526,4.526,0,0,1-4.04,4.84179l-22.72608,2.27247a4.47392,4.47392,0,0,1-4.79028-3.29786l-41.61182-147.207a1.50024,1.50024,0,0,0-2.82519-.20019L350.55285,774.40649A4.48315,4.48315,0,0,1,346.43225,777.12817Z"
        transform="translate(-204.57225 -97.18115)" fill="#2f2e41" />
    <circle cx="212.3703" cy="335.46934" r="24.56103" fill="#ffb8b8" />
    <path
        d="M385.87243,551.61157,385.704,551.26c-.11425-.23926-11.43017-24.24512-10.37719-51.93457a35.6575,35.6575,0,0,1,34.9082-34.2832h0a35.59915,35.59915,0,0,1,35.64136,28.79882c3.15356,16.09864,3.0747,31.34668-.23462,45.31836l-.07373.31055-.31324.06445Z"
        transform="translate(-204.57225 -97.18115)" fill="#6c63ff" />
    <path
        d="M511.09616,586.26057a10.05579,10.05579,0,0,1-8.06448-13.1423L474.742,551.2854l17.56457-6.02657,24.1715,21.65681a10.11027,10.11027,0,0,1-5.38193,19.34493Z"
        transform="translate(-204.57225 -97.18115)" fill="#ffb8b8" />
    <path
        d="M441.67292,538.12873l-.0654-.10563-29.82327-48.29827a13.42932,13.42932,0,0,1-.429-12.27495,12.87649,12.87649,0,0,1,9.43853-7.28423h0a16.85435,16.85435,0,0,1,3.12653-.27252,12.92087,12.92087,0,0,1,10.71335,6.09016l40.27682,53.253,33.38133,23.60723a4.49959,4.49959,0,0,1,.86955,6.54188l-7.77148,9.39628a4.48311,4.48311,0,0,1-5.74532,1.01332Z"
        transform="translate(-204.57225 -97.18115)" fill="#6c63ff" />
    <path
        d="M412.55028,431.12649c1.81023-2.97777,5.805-3.819,9.27322-3.47935s6.841,1.55875,10.32574,1.53174c9.67615-.075,17.14516-11.8682,13.077-20.64791-3.33632-7.2003-11.66886-10.362-19.2066-12.84345-15.80415-5.20283-33.95835-10.20184-48.29972-1.76586a30.01039,30.01039,0,0,0-14.44477,26.741c.32255,6.63919,2.86521,13.45884.71479,19.7484-2.48082,7.25593-10.34666,11.14893-17.78013,13.03212-13.03063,3.30118-26.7696,2.76942-39.88173,5.73032s-26.54852,10.82279-30.083,23.79209c-2.32156,8.51874.10094,17.68937,4.11272,25.55475A66.18546,66.18546,0,0,0,372.69066,535.603c15.85168-9.25607,27.296-24.89963,34.11893-41.94069a140.43809,140.43809,0,0,0,8.1175-30.84631c1.52807-9.88241.23148-19.86929-2.53628-29.47849A2.70887,2.70887,0,0,1,412.55028,431.12649Z"
        transform="translate(-204.57225 -97.18115)" fill="#2f2e41" />
    <path d="M586.57225,802.81885h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z" transform="translate(-204.57225 -97.18115)"
        fill="#3f3d56" />
</svg>
    );
  }
}
