import React, { Component } from "react";

export default class FullStackImg extends Component {
  render() {
    const theme = this.props.theme;
    return (

      <svg xmlns="http://www.w3.org/2000/svg" width="583.04004" height="431.02025" viewBox="0 0 583.04004 431.02025"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      >
      <g>
          <path
              d="M291.52026,308.9511c-64.43262,0-125.03809-9.5918-170.65234-27.00781-46.22217-17.64844-71.67773-41.35547-71.67773-66.75488s25.45557-49.10547,71.67773-66.75391c45.61426-17.41602,106.21973-27.00781,170.65234-27.00781s125.0376,9.5918,170.65234,27.00781c46.22168,17.64844,71.67725,41.35547,71.67725,66.75391s-25.45557,49.10645-71.67725,66.75488c-45.61475,17.41602-106.21973,27.00781-170.65234,27.00781Zm0-183.52441c-63.95703,0-124.05566,9.49805-169.22559,26.74463-44.56299,17.01514-69.10449,39.39502-69.10449,63.01709s24.5415,46.00293,69.10449,63.01758c45.16992,17.24707,105.26855,26.74512,169.22559,26.74512s124.05566-9.49805,169.2251-26.74512c44.56299-17.01465,69.10449-39.39453,69.10449-63.01758s-24.5415-46.00195-69.10449-63.01709c-45.16992-17.24658-105.26855-26.74463-169.2251-26.74463Z"
              fill="#61dafb" />
          <path
              d="M391.64233,430.37786c-20.02393,0-44.98486-11.57324-72.60693-34.02246-37.89062-30.79492-76.5-78.48535-108.71582-134.28516-32.21631-55.7998-54.2124-113.08203-61.93652-161.29346-7.82715-48.854-.02393-82.75244,21.97217-95.45166,21.99609-12.69873,55.25537-2.50732,93.64941,28.69727,37.89062,30.79541,76.5,78.48535,108.71582,134.28516,32.21631,55.7998,54.2124,113.08203,61.93652,161.29395,7.82715,48.85352,.02393,82.75195-21.97217,95.45215-6.17139,3.5625-13.23291,5.32422-21.04248,5.32422ZM191.50464,3.96917c-7.13916,0-13.56201,1.59375-19.14941,4.81982-20.45801,11.81152-27.56885,44.25488-20.02246,91.35498,7.64893,47.7417,29.47266,104.5376,61.45068,159.92627,31.97852,55.3877,70.25342,102.68555,107.77441,133.18066,37.01709,30.08594,68.67041,40.15137,89.12695,28.33789,20.45801-11.81152,27.56885-44.25488,20.02246-91.35449-7.64893-47.74219-29.47266-104.53809-61.45068-159.92676-31.97852-55.3877-70.25342-102.68555-107.7749-133.18066C234.57593,15.25872,210.50366,3.96917,191.50464,3.96917Z"
              fill="#61dafb" />
          <path
              d="M191.39722,430.37786c-7.81152,0-14.86914-1.76074-21.04199-5.32422-21.99609-12.7002-29.79932-46.59863-21.97217-95.45215,7.72412-48.21191,29.72021-105.49414,61.93652-161.29395,32.21582-55.7998,70.8252-103.48975,108.71582-134.28516C357.42993,2.81731,390.6897-7.37507,412.68481,5.32512c21.99609,12.69922,29.79932,46.59766,21.97217,95.45166-7.72412,48.21143-29.72021,105.49365-61.93652,161.29346-32.21582,55.7998-70.8252,103.49023-108.71582,134.28516-27.61963,22.44824-52.58398,34.02246-72.60742,34.02246ZM391.5354,3.96917c-18.99951,0-43.06885,11.28809-69.97754,33.15771-37.521,30.49512-75.7959,77.79297-107.77441,133.18066-31.97803,55.38867-53.80176,112.18457-61.45068,159.92676-7.54639,47.09961-.43555,79.54297,20.02246,91.35449,5.58643,3.22559,12.01221,4.81934,19.14941,4.82031,18.99951,.00195,43.06885-11.28809,69.97705-33.1582,37.52148-30.49512,75.79639-77.79297,107.7749-133.18066,31.97803-55.38867,53.80176-112.18457,61.45068-159.92627,7.54639-47.1001,.43555-79.54346-20.02246-91.35498-5.58643-3.2251-12.01221-4.81982-19.14941-4.81982Z"
              fill="#61dafb" />
          <circle cx="291.52004" cy="215.18889" r="44.78878" fill="#61dafb" />
      </g>
      <g>
          <path
              d="M564.784,338.78603c-.84428-1.42364-4.4968,.10067-5.16831-1.19134-.66912-1.28736,2.72451-3.24887,4.66333-7.72979,.34964-.80809,2.55299-5.90033,.92185-7.47163-3.09172-2.97835-17.23961,9.31259-22.19076,5.29042-1.08658-.88268-1.84568-2.64485-5.1343-5.50582-1.30828-1.13814-2.09937-1.65203-2.86498-1.46084-1.08634,.27126-1.17245,1.72523-2.33734,4.58397-1.74699,4.28728-2.7803,4.02841-3.79817,7.44896-.75561,2.53926-.58301,4.01552-1.47217,4.30314-1.25824,.40702-2.4164-2.28633-4.01098-2.04517-1.6272,.24612-2.61095,3.38204-2.92167,5.72997-.5831,4.40607,1.03497,7.31348,1.9318,10.37063,.97462,3.32235,1.55303,8.37495-.69771,15.5276l-22.27719,62.76614c4.91811-12.87943,19.15839-48.18537,25.14217-61.3053,1.72739-3.78744,3.6347-7.62908,7.55099-9.14527,3.77045-1.45978,8.94865-.58824,15.55027-2.17862,.77101-.18571,2.91052-.73007,3.17983-1.98848,.22264-1.04038-1.03585-1.62109-.84249-2.59549,.25936-1.30702,2.73415-1.32305,5.77532-2.83097,2.14444-1.06331,3.48044-2.32786,4.62932-3.41531,.34638-.32785,5.4951-5.26165,4.37117-7.15679Z"
              fill="#f2f2f2" />
          <path
              d="M477.13888,371.82876c-.94234,.00361-1.24806,2.23611-2.07594,2.1929-.82491-.04303-.80846-2.27461-2.44831-4.51909-.29573-.40476-2.1593-2.95546-3.40114-2.60826-2.35386,.65809-.4035,11.14829-3.80617,12.41783-.74674,.27862-1.83065,.14275-4.1837,.93134-.93609,.31372-1.41656,.55372-1.54363,.98466-.18031,.61145,.50846,1.07323,1.57545,2.4698,1.60017,2.09445,1.17496,2.52692,2.5601,4.01343,1.02827,1.10352,1.80263,1.44475,1.68724,1.96415-.16327,.73499-1.81935,.5263-2.16108,1.37852-.3487,.86966,.90659,2.2573,1.96931,3.08725,1.99428,1.55748,3.88813,1.6022,5.64736,2.04412,1.91184,.48026,4.55858,1.6542,7.41974,4.82265l24.37836,29.04383c-4.90228-6.12997-18.1219-23.30609-22.83473-30.02849-1.36049-1.94061-2.69568-3.98517-2.30986-6.34478,.37143-2.27175,2.29326-4.56178,3.41747-8.26078,.13131-.432,.48146-1.63915-.05848-2.13442-.44639-.40946-1.09452,.04067-1.51697-.33538-.56666-.50442,.13953-1.72368,.2769-3.65139,.09686-1.35931-.13831-2.37988-.34055-3.25753-.06097-.2646-.99694-4.21519-2.25138-4.21036Z"
              fill="#f2f2f2" />
          <path
              d="M495.93851,376.04886l-.56775-5.06817,.2594-.38421c1.20036-1.77667,1.81216-3.51174,1.8195-5.15871,.00122-.26185-.01101-.5237-.02325-.79045-.04894-1.05719-.11012-2.37134,.57509-3.9033,.38421-.85408,1.46343-2.83142,3.08593-2.5867,.43683,.06118,.7672,.26185,1.01804,.49678,.03671-.05873,.07464-.11747,.11502-.18109,.50535-.77087,.90547-1.10124,1.2909-1.41938,.29611-.24472,.60201-.49678,1.08167-1.0939,.21046-.26185,.37442-.49189,.51269-.68522,.4197-.58488,.96787-1.28234,1.93452-1.2701,1.03272,.04894,1.57355,.90302,1.93207,1.46832,.63994,1.00825,.92994,1.73507,1.12204,2.21717,.06975,.1762,.14928,.37442,.18966,.4356,.33282,.48944,3.03821,.03181,4.05747-.1346,2.28936-.38176,4.27038-.71214,5.08652,.73171,.58488,1.03272,.1505,2.39826-1.33006,4.16759-.4613,.55062-.95196,.96909-1.38757,1.29702,.36096,.21535,.68399,.54573,.81492,1.06454h0c.30835,1.2285-.74762,2.45944-3.13732,3.66347-.59345,.30101-1.39246,.70235-2.51206,.89568-.52737,.09055-1.00213,.11257-1.40837,.12481-.00857,.23493-.06363,.48944-.20679,.75129-.4197,.77087-1.29335,1.13795-2.60995,1.04985-1.44875-.07586-2.64176-.3524-3.69406-.59467-.91893-.21046-1.7106-.38911-2.33219-.3475-1.15263,.09299-2.04097,1.01559-3.08348,2.21228l-2.6026,3.04188Z"
              fill="#f2f2f2" />
          <path
              d="M498.5722,345.87291l-4.75841,1.83473-.43744-.15347c-2.02304-.71045-3.85655-.86214-5.45152-.45149-.2536,.06524-.50378,.14349-.7587,.22299-1.0102,.3155-2.26586,.70801-3.92151,.43379-.9236-.15501-3.11002-.69738-3.28485-2.32889-.05162-.43806,.05869-.80853,.22231-1.11075-.06612-.02061-.13256-.0424-.20435-.06532-.87384-.29329-1.2949-.59652-1.7004-.88866-.31183-.22435-.63324-.45632-1.33249-.76882-.30667-.13716-.57077-.23741-.79285-.32212-.67221-.25761-1.4859-.61095-1.71925-1.54909-.21461-1.01136,.47435-1.75114,.93023-2.24132,.81295-.87476,1.44245-1.33963,1.86005-1.64773,.15275-.11216,.32431-.23937,.37325-.29395,.38901-.44608-.73987-2.94692-1.15937-3.89063-.94998-2.11766-1.77203-3.95008-.58241-5.10577,.85059-.8277,2.28166-.7539,4.36867,.22945,.64962,.30655,1.17887,.67502,1.60656,1.0132,.11675-.40378,.35438-.80005,.82302-1.05829h0c1.11011-.60987,2.56864,.09933,4.33944,2.10548,.44169,.49769,1.03258,1.16877,1.50357,2.20271,.22135,.48716,.36308,.9408,.47796,1.33065,.22942-.05131,.48957-.0626,.77917,.00946,.85212,.21044,1.42879,.96241,1.67753,2.2583,.29409,1.42061,.32921,2.64475,.36178,3.72409,.02951,.94226,.05751,1.75335,.25542,2.34406,.38232,1.09135,1.50007,1.71662,2.92205,2.42149l3.60255,1.74592Z"
              fill="#f2f2f2" />
          <path
              d="M495.93851,330.94194l-.56775-5.06817,.2594-.38421c1.20036-1.77667,1.81216-3.51174,1.8195-5.15871,.00122-.26185-.01101-.5237-.02325-.79045-.04894-1.05719-.11012-2.37134,.57509-3.9033,.38421-.85408,1.46343-2.83142,3.08593-2.5867,.43683,.06118,.7672,.26185,1.01804,.49678,.03671-.05873,.07464-.11747,.11502-.18109,.50535-.77087,.90547-1.10124,1.2909-1.41938,.29611-.24472,.60201-.49678,1.08167-1.0939,.21046-.26185,.37442-.49189,.51269-.68522,.4197-.58488,.96787-1.28234,1.93452-1.2701,1.03272,.04894,1.57355,.90302,1.93207,1.46832,.63994,1.00825,.92994,1.73507,1.12204,2.21717,.06975,.1762,.14928,.37442,.18966,.4356,.33282,.48944,3.03821,.03181,4.05747-.1346,2.28936-.38176,4.27038-.71214,5.08652,.73171,.58488,1.03272,.1505,2.39826-1.33006,4.16759-.4613,.55062-.95196,.96909-1.38757,1.29702,.36096,.21535,.68399,.54573,.81492,1.06454h0c.30835,1.2285-.74762,2.45944-3.13732,3.66347-.59345,.30101-1.39246,.70235-2.51206,.89568-.52737,.09055-1.00213,.11257-1.40837,.12481-.00857,.23493-.06363,.48944-.20679,.75129-.4197,.77087-1.29335,1.13795-2.60995,1.04985-1.44875-.07586-2.64176-.3524-3.69406-.59467-.91893-.21046-1.7106-.38911-2.33219-.3475-1.15263,.09299-2.04097,1.01559-3.08348,2.21228l-2.6026,3.04188Z"
              fill="#f2f2f2" />
          <path
              d="M501.12414,429.31966l-1.06209-.66564-.25696-1.22605,.25696,1.22605-1.23829,.14194c-.01958-.11502-.08688-.37932-.18843-.7929-.55429-2.26611-2.24409-9.16234-3.65368-20.02305-.98378-7.58145-1.54052-15.3758-1.65554-23.17016-.11502-7.80659,.25451-13.7166,.55062-18.46662,.22392-3.58271,.49556-6.99412,.76231-10.31743,.70969-8.87847,1.379-17.26505,.88099-26.54975-.11012-2.07278-.34139-6.38721-2.86201-10.97817-1.46221-2.66256-3.47626-5.02656-5.98709-7.02348l1.56132-1.96266c2.77146,2.20738,4.99964,4.82589,6.62337,7.78212,2.79471,5.09019,3.04677,9.79128,3.16791,12.05005,.50657,9.44867-.17008,17.916-.88711,26.88012-.2643,3.31107-.53594,6.70779-.75863,10.27582-.29366,4.70598-.65952,10.56459-.54573,18.27085,.11257,7.70136,.66319,15.40027,1.63351,22.88628,1.39246,10.72366,3.05779,17.51711,3.60474,19.74896,.29122,1.19179,.3524,1.4414,.05384,1.91372Z"
              fill="#f2f2f2" />
          <path
              d="M482.07508,315.45601c-.10278,0-.20679-.00245-.31202-.00979-2.13396-.11502-4.11008-1.39246-5.87452-3.79562-.82716-1.13061-1.25052-2.42029-2.09481-4.9923-.13093-.39645-.7672-2.40805-1.15386-5.17584-.25329-1.80849-.22147-2.56467,.13704-3.23276,.39767-.74395,1.04129-1.26276,1.76566-1.61271-.03181-.23983-.00612-.48699,.09789-.73906,.42704-1.04496,1.56254-.90791,2.17679-.84184,.3108,.03916,.69868,.09055,1.11715,.06852,.6583-.03181,1.01192-.22759,1.54786-.52126,.51269-.28143,1.15019-.63138,2.09603-.82471,1.86477-.38911,3.42731,.13949,3.94245,.31324,2.71028,.90302,4.04646,3.01006,5.59309,5.44993,.30835,.48944,1.36677,2.27101,2.06544,4.71332,.50412,1.76199,.43316,2.5451,.28143,3.12753-.30835,1.19668-1.03639,1.8819-2.88526,3.39428-1.93085,1.58334-2.89994,2.37624-3.73199,2.85834-1.93696,1.11837-3.15323,1.82072-4.76838,1.82072Z"
              fill="#f2f2f2" />
      </g>
      <g>
          <g>
              <path id="b-663"
                  d="M243.46957,228.73951c4.16581,1.09909,8.10965-.15946,8.80868-2.81073,.69903-2.65126-2.11125-5.69069-6.2787-6.78961-1.6587-.46181-3.40388-.51968-5.08952-.16875l-17.70953-4.43397-1.90359,8.35491,17.66056,3.48408c1.29365,1.13707,2.84086,1.94771,4.5121,2.36407Z"
                  fill="#ffb6b6" />
              <path
                  d="M146.13321,188.06167s-3.59702,6.90329,1.38855,10.30819,46.50224,19.1858,46.50224,19.1858l41.23021,8.97281,1.48803-10.02002-37.53799-11.99262-31.30368-21.05293-21.76737,4.59877Z"
                  fill="#2f2e41" />
          </g>
          <g>
              <polygon
                  points="195.229 410.86965 202.22161 407.72429 191.21187 374.81567 180.89222 379.45676 195.229 410.86965"
                  fill="#ffb6b6" />
              <path
                  d="M225.85447,413.94428h0c.55892,1.16809,.18308,2.51167-.83949,3.00096l-13.72475,6.56716s-3.94843-4.78291-10.57313-4.48447l3.33572,7.9475-7.08012,3.38777-5.11698-12.89726s-5.09051-5.77423-2.79113-11.07118c2.29943-5.29697-.65903-3.29717-.65903-3.29717l-2.15062-8.46877,11.30082-3.86117,5.88359,12.09411,10.95399,9.55755,9.41555,.11799c.83803,.01049,1.64185,.56344,2.04548,1.407l.0001-.00002Z"
                  fill="#2f2e41" />
          </g>
          <g>
              <polygon
                  points="56.86064 393.87943 63.10936 398.32278 85.93805 372.1877 76.71694 365.62982 56.86064 393.87943"
                  fill="#ffb6b6" />
              <path
                  d="M69.68929,421.85797h0c-.72531,1.07273-2.07457,1.42764-3.01366,.79269l-12.60429-8.52223s2.13082-5.8246-1.47554-11.38966l-5.17102,6.89567-6.50211-4.39632,8.54133-10.93474s2.40882-7.31113,8.1415-8.00484c5.73272-.69368,2.51172-2.23537,2.51172-2.23537l6.21986-6.13665,9.04418,7.79872-7.46083,11.19019-2.70909,14.28277,4.65786,8.18357c.41458,.72837,.34382,1.70145-.17998,2.47614l.00007,.00007Z"
                  fill="#2f2e41" />
          </g>
          <rect x="134.65437" y="143.3215" width="32.83387" height="45.09185" fill="#2f2e41" />
          <path
              d="M160.11457,169.0948l-17.82774-5.30014-3.37281,9.15478-.48183,55.89236s25.53703-5.78197,31.319,3.85465l1.92732-17.34591s6.2638-8.67295,.96366-19.75506c-5.30014-11.08211-10.29676-16.07873-10.29676-16.07873l-4.15816-2.23084,1.92732-8.19112Z"
              fill="#6c63ff" />
          <path
              d="M139.63676,220.40977l-2.40915,24.57337,31.80083,6.74563s-2.40915-21.20055,.48183-26.01886l-29.8735-5.30014Z"
              fill="#ffb6b6" />
          <path
              d="M170.95576,251.24693s-20.23689-14.93675-37.5828-9.15478c0,0-20.47781,12.76851-9.87753,34.93273l-16.14133,38.30554-36.1373,50.59222,10.11844,12.04577,47.70124-49.62856,15.41858-21.20055,39.02829,82.87488,12.04577-5.78197-14.93675-63.60165-8.91387-44.08751-.72275-25.29611Z"
              fill="#2f2e41" />
          <polygon
              points="123.73635 279.67495 99.64482 271.96565 122.77269 207.88217 120.36354 173.67219 140.76819 167.91669 152.16436 218.48244 123.73635 279.67495"
              fill="#2f2e41" />
          <path
              d="M161.01373,180.84843l-3.64218-3.64218,1.62441-3.35736s10.51431,10.9054,12.44163,13.79639,19.92495,75.50218,19.92495,75.50218l-27.15241,11.22735,5.30014-57.01347,1.96242-12.82373c.29018-1.89622-.03439-3.83553-.92617-5.53393l-9.53279-18.15524Z"
              fill="#2f2e41" />
          <circle cx="152.15161" cy="148.56012" r="15.97725" fill="#ffb6b6" />
          <path
              d="M162.08868,142.02914c-5.48186-3.65576-15.29302,10.98544-18.29302,1.98544-.46778-1.40333-1.56818,4.80515-1.97081,6.97741-.51132,2.7587,.78247,4.96207,0,5.45565-.88427,.55775-3.04973-1.93237-3.5072-1.55876-.44711,.36514,.99011,3.25903,3.5072,6.62471,3.3409,4.46725,5.43481,5.55454,8.18347,8.57316,2.559,2.81036,7.29378,8.01022,6.62471,12.47005-1.25845,8.38835-21.74082,14.87117-28.05761,8.57316-1.51242-1.50795-1.80826-4.92268-2.33813-11.69067-.78856-10.07203-.19318-17.63024,0-19.87414,.8043-9.34345,1.2065-14.0152,3.11751-17.9257,1.22035-2.49721,5.33002-10.90677,14.4185-13.63912,9.76297-2.93508,21.38224,1.85012,24.55041,8.96285,.91543,2.05514,.02569,1.83604,1.16907,8.57316,1.35316,7.97331,2.22911,12.75775,5.84534,15.58756,3.32213,2.59972,5.88591,1.14657,7.0144,3.11751,2.92707,5.1122-10.65637,21.28895-16.36694,19.09476-3.44075-1.32205-3.74491-9.2064-3.5072-12.85974,.5119-7.86751,4.41031-9.53735,4.28658-16.75663-.03273-1.91006-.14861-8.67125-4.67627-11.69067Z"
              fill="#2f2e41" />
          <g>
              <path id="c-664"
                  d="M92.48172,275.54219c-.67837,4.25462,.96703,8.05336,3.67477,8.48463,2.70774,.43127,5.4519-2.66798,6.12994-6.92421,.29417-1.69647,.17779-3.43873-.33941-5.081l2.6466-18.0633-8.50305-1.0613-1.70633,17.91989c-1.00245,1.40055-1.65484,3.02086-1.90253,4.72528Z"
                  fill="#ffb6b6" />
              <path
                  d="M123.25452,174.63585s-7.22746-2.89098-10.11844,2.40915-14.45492,48.18307-14.45492,48.18307l-4.81831,41.91927,10.11844,.48183,8.19112-38.54646,17.82774-33.24632-6.74563-21.20055Z"
                  fill="#2f2e41" />
          </g>
      </g>
      <path
          d="M583.04004,430.18022h0c0,.46394-.37609,.84003-.84003,.84003H0v-1.68005H582.20001c.46393,0,.84003,.37609,.84003,.84003Z"
          fill="#e6e6e6" />
  </svg>
    );
  }
}
