import React, { Component } from "react";

export default class DataScienceImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="854.23578" height="649.46102"
     viewBox="0 0 854.23578 649.46102" 
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    >
    <path
        d="M773.85715,625.57712c-75.50269,0-143.73169-52.37891-160.69385-129.07862-15.70312-71.00488,29.28833-141.54687,100.29321-157.25a105.70594,105.70594,0,0,1,126.03833,80.38672,84.86141,84.86141,0,0,1-64.46118,101.06934,68.36083,68.36083,0,0,1-76.46094-37.92139,1,1,0,1,1,1.8169-.83593,66.34457,66.34457,0,0,0,74.21216,36.80419,82.85908,82.85908,0,0,0,62.94043-98.68457,103.70676,103.70676,0,0,0-123.65406-78.86523,130.03032,130.03032,0,0,0-98.77221,154.86523c19.36084,87.544,106.33252,143.01612,193.87988,123.65528A203.22663,203.22663,0,0,0,945.23874,515.6152a1.00011,1.00011,0,1,1,1.77051.93067A205.22438,205.22438,0,0,1,809.42771,621.67526,164.62266,164.62266,0,0,1,773.85715,625.57712Z"
        transform="translate(-172.88211 -125.26949)" fill="#e6e6e6" />
    <path
        d="M968.43381,400.45847a1.00026,1.00026,0,0,1-.99317-.89356,206.60093,206.60093,0,0,0-3.6853-22.49072C937.63815,258.98044,831.0549,174.96774,710.3237,177.42331a1.0002,1.0002,0,0,1-.04-2A256.719,256.719,0,0,1,965.708,376.64255a208.60288,208.60288,0,0,1,3.72143,22.70947.99979.99979,0,0,1-.88769,1.10059A1.01075,1.01075,0,0,1,968.43381,400.45847Z"
        transform="translate(-172.88211 -125.26949)" fill="#e6e6e6" />
    <path
        d="M416.93063,564.45847a.99983.99983,0,0,1-.97534-.78418,320.26993,320.26993,0,0,1,104.523-312.79883A1.00007,1.00007,0,1,1,521.78,252.394,318.27334,318.27334,0,0,0,417.90793,563.24265a.99918.99918,0,0,1-.9773,1.21582Z"
        transform="translate(-172.88211 -125.26949)" fill="#e6e6e6" />
    <path
        d="M693.30366,286.26949h-156a17.01917,17.01917,0,0,1-17-17v-127a17.01917,17.01917,0,0,1,17-17h156a17.01917,17.01917,0,0,1,17,17v127A17.01917,17.01917,0,0,1,693.30366,286.26949Zm-156-159a15.017,15.017,0,0,0-15,15v127a15.017,15.017,0,0,0,15,15h156a15.017,15.017,0,0,0,15-15v-127a15.017,15.017,0,0,0-15-15Z"
        transform="translate(-172.88211 -125.26949)" fill="#3f3d56" />
    <path id="b42f7784-d7aa-4130-a713-c57f7e285794-4910" data-name="Path 411"
        d="M675.57215,163.4075H596.841a2.73088,2.73088,0,0,1-2.77562-2.48906,2.63064,2.63064,0,0,1,2.62678-2.77193l80.14349,0c2.64021,2.32,1.03127,5.261-1.26446,5.261Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="fef78de7-8139-4292-a735-dfc1826a946d-4911" data-name="Path 412"
        d="M675.57215,177.21748H596.841a2.73088,2.73088,0,0,1-2.77562-2.48906,2.63064,2.63064,0,0,1,2.62678-2.77193h80.14349c2.64021,2.32,1.03127,5.261-1.26446,5.261Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="bc3a1040-a005-4464-8914-0d26e9886da7-4912" data-name="Path 413"
        d="M579.82115,185.08048h-24.028a2.962,2.962,0,0,1-2.959-2.959v-28.355a2.962,2.962,0,0,1,2.959-2.959h24.028a2.963,2.963,0,0,1,2.959,2.959v28.355a2.962,2.962,0,0,1-2.959,2.959Z"
        transform="translate(-172.88211 -125.26949)" fill="#6c63ff" />
    <path id="ef5725be-974e-4818-8827-9633ad97864d-4913" data-name="Path 414"
        d="M675.34315,205.49349H555.185a2.73086,2.73086,0,0,1-2.77559-2.48905,2.63061,2.63061,0,0,1,2.62677-2.77194H676.60761c2.64021,2.32,1.03127,5.261-1.26446,5.261Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="b6ceb078-02d4-45e5-a259-b4aa725e8656-4914" data-name="Path 415"
        d="M675.34315,219.3075l-120.15819,0a2.73086,2.73086,0,0,1-2.77559-2.48905,2.63061,2.63061,0,0,1,2.62677-2.77194l121.57147,0c2.64021,2.32,1.03127,5.261-1.26446,5.261Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="b70683a0-764f-4858-8fd1-ac4cf933a79b-4915" data-name="Path 416"
        d="M675.34315,233.11249H555.185a2.73085,2.73085,0,0,1-2.77559-2.48905,2.63061,2.63061,0,0,1,2.62677-2.77194H676.60761c2.64021,2.32,1.03127,5.261-1.26446,5.261Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="ff94e3a1-018f-4284-9dff-97f10413b838-4916" data-name="Path 417"
        d="M675.34315,246.92548H555.185a2.73085,2.73085,0,0,1-2.77559-2.489,2.63061,2.63061,0,0,1,2.62677-2.77194H676.60761c2.64021,2.32,1.03127,5.261-1.26446,5.261Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="a482e522-b4bb-4a24-a9c3-635a0ec9eabe-4917" data-name="Path 418"
        d="M675.34315,260.7315H555.185a2.73085,2.73085,0,0,1-2.77559-2.489,2.63061,2.63061,0,0,1,2.62677-2.77194H676.60761c2.64021,2.32,1.03127,5.261-1.26446,5.261Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path
        d="M1013.80368,517.08054H900.80344a13.3291,13.3291,0,0,1-13.314-13.314V411.77243a13.32909,13.32909,0,0,1,13.314-13.314h113.00024a13.32914,13.32914,0,0,1,13.31421,13.314v91.99414A13.32915,13.32915,0,0,1,1013.80368,517.08054ZM900.80344,400.45847a11.32664,11.32664,0,0,0-11.314,11.314v91.99414a11.32665,11.32665,0,0,0,11.314,11.314h113.00024a11.3267,11.3267,0,0,0,11.31421-11.314V411.77243a11.32669,11.32669,0,0,0-11.31421-11.314Z"
        transform="translate(-172.88211 -125.26949)" fill="#3f3d56" />
    <path id="e7b4c438-63a5-4348-a2ea-6daf9e8a2e2f-4918" data-name="Path 411"
        d="M1000.95978,427.08413l-57.02975,0a1.97814,1.97814,0,0,1-2.01055-1.803,1.90553,1.90553,0,0,1,1.90273-2.00788H1001.875c1.91246,1.68052.747,3.81085-.91593,3.81085Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="a00103cd-61d1-44c3-b135-68efb90ff9bc-4919" data-name="Path 412"
        d="M1000.95978,437.08754H943.93a1.97814,1.97814,0,0,1-2.01055-1.803,1.90552,1.90552,0,0,1,1.90273-2.00787l58.05279,0c1.91246,1.68052.747,3.81086-.91593,3.81086Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="f4350b45-c9af-4d8c-9c78-cb2977c503c8-4920" data-name="Path 413"
        d="M931.60153,442.78319H914.1966a2.14555,2.14555,0,0,1-2.14339-2.14339V420.10057a2.14556,2.14556,0,0,1,2.14339-2.1434h17.40493a2.1463,2.1463,0,0,1,2.14339,2.1434v20.53925a2.14555,2.14555,0,0,1-2.14339,2.14339Z"
        transform="translate(-172.88211 -125.26949)" fill="#6c63ff" />
    <path id="f5a14ccf-41cd-4d0f-84ee-cd82fbc8c163-4921" data-name="Path 414"
        d="M1000.7939,457.56956l-87.03785,0a1.97814,1.97814,0,0,1-2.01054-1.803,1.90552,1.90552,0,0,1,1.90274-2.00789h88.06157c1.91247,1.68052.747,3.81085-.91592,3.81085Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="a4da95c7-64c3-4299-a88e-05bfbb3353e0-4922" data-name="Path 415"
        d="M1000.7939,467.57589l-87.03785,0a1.97814,1.97814,0,0,1-2.01054-1.803,1.90552,1.90552,0,0,1,1.90274-2.00789h88.06157c1.91247,1.68052.747,3.81085-.91592,3.81085Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="af1ad797-b30d-429c-a4be-fed0ef064171-4923" data-name="Path 416"
        d="M1000.7939,477.57568H913.75605a1.97814,1.97814,0,0,1-2.01054-1.803,1.90552,1.90552,0,0,1,1.90274-2.00788h88.06157c1.91247,1.68051.747,3.81085-.91592,3.81085Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="b13c38b9-0b26-4a00-b050-dd9bdfa2db99-4924" data-name="Path 417"
        d="M1000.7939,487.58126l-87.03785,0a1.97814,1.97814,0,0,1-2.01054-1.803,1.90552,1.90552,0,0,1,1.90274-2.00789h88.06157c1.91247,1.68052.747,3.81085-.91592,3.81085Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path id="ed4b3cd1-8212-4136-9638-47564b0bc4c1-4925" data-name="Path 418"
        d="M1000.7939,497.5818H913.75605a1.97814,1.97814,0,0,1-2.01054-1.803,1.90552,1.90552,0,0,1,1.90274-2.00788h88.06157c1.91247,1.68052.747,3.81085-.91592,3.81085Z"
        transform="translate(-172.88211 -125.26949)" fill="#ccc" />
    <path
        d="M736.12448,483.73825h-71.6416a8.817,8.817,0,0,1-8.80713-8.80713V416.60788a8.817,8.817,0,0,1,8.80713-8.80713h71.6416a8.817,8.817,0,0,1,8.80713,8.80713v58.32324A8.817,8.817,0,0,1,736.12448,483.73825Zm-71.6416-73.9375a6.8149,6.8149,0,0,0-6.80713,6.80713v58.32324a6.8149,6.8149,0,0,0,6.80713,6.80713h71.6416a6.8149,6.8149,0,0,0,6.80713-6.80713V416.60788a6.8149,6.8149,0,0,0-6.80713-6.80713Z"
        transform="translate(-172.88211 -125.26949)" fill="#3f3d56" />
    <path d="M724.80366,452.01949h-49a4.5,4.5,0,0,1,0-9h49a4.5,4.5,0,0,1,0,9Z"
        transform="translate(-172.88211 -125.26949)" fill="#6c63ff" />
    <path d="M714.80366,433.51949h-29a4.5,4.5,0,0,1,0-9h29a4.5,4.5,0,0,1,0,9Z"
        transform="translate(-172.88211 -125.26949)" fill="#6c63ff" />
    <path d="M724.80366,467.01949h-49a4.5,4.5,0,0,1,0-9h49a4.5,4.5,0,0,1,0,9Z"
        transform="translate(-172.88211 -125.26949)" fill="#6c63ff" />
    <path
        d="M299.27944,598.87168a10.05577,10.05577,0,0,1,5.773-14.29782l.73682-35.72725,15.28828,10.5405L318.034,591.69834a10.11027,10.11027,0,0,1-18.75459,7.17334Z"
        transform="translate(-172.88211 -125.26949)" fill="#a0616a" />
    <path
        d="M316.18432,461.30165,301.21969,573.81551l18.015.67923,15.05548-118.11153-3.61333-2.13183A9.66544,9.66544,0,0,0,316.18432,461.30165Z"
        transform="translate(-172.88211 -125.26949)" fill="#6c63ff" />
    <polygon points="191.064 637.552 203.323 637.551 209.156 590.263 191.061 590.264 191.064 637.552" fill="#a0616a" />
    <path
        d="M361.31874,759.31752h38.53072a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H376.20562a14.88688,14.88688,0,0,1-14.88688-14.88688v0A0,0,0,0,1,361.31874,759.31752Z"
        transform="translate(588.32109 1408.23505) rotate(179.99738)" fill="#2f2e41" />
    <polygon points="89.061 622.9 100.186 628.05 125.346 587.589 108.926 579.988 89.061 622.9" fill="#a0616a" />
    <path
        d="M256.12064,751.29193h38.53071a0,0,0,0,1,0,0V766.1788a0,0,0,0,1,0,0H271.00753a14.88689,14.88689,0,0,1-14.88689-14.88689v0A0,0,0,0,1,256.12064,751.29193Z"
        transform="translate(33.66226 1437.69074) rotate(-155.15884)" fill="#2f2e41" />
    <path
        d="M376.79831,752.82956a4.62614,4.62614,0,0,1-.53711-.03174l-9.69946-1.14111a4.52376,4.52376,0,0,1-3.9541-4.042L350.96433,625.4736a1.49986,1.49986,0,0,0-2.8064-.582l-64.781,117.36621a4.48379,4.48379,0,0,1-5.14416,2.16113l-10.89624-3.02685a4.49724,4.49724,0,0,1-3.0647-5.75928c9.48963-28.394,57.03357-170.70019,61.09851-183.79785,4.85706-15.65039,41.11475-21.81738,44.23877-22.32031l1.79468-1.79492.34546.46484c16.00366,21.54346,23.74756,50.07568,21.80542,80.3418l-.001.01123L381.26975,748.72165a4.502,4.502,0,0,1-4.47144,4.10791Z"
        transform="translate(-172.88211 -125.26949)" fill="#2f2e41" />
    <circle cx="152.81659" cy="285.84822" r="24.56103" fill="#a0616a" />
    <path
        d="M326.95175,548.07175l-14.57361-75.96192a25.74614,25.74614,0,0,1,18.81434-29.72412,22.19944,22.19944,0,0,1,21.96765,6.50195c7.439,8.12061,16.88818,19.938,19.6604,30.124,2.34668,8.646,8.95093,41.04541,9.01733,41.37158l.05249.25732-.18262.18946c-.03491.03613-3.57324,3.69287-9.08081,8.25732-3.23193,2.67871-6.53515,5.16455-9.81836,7.38965-9.21826,6.22461-34.29956,11.28369-35.36279,11.49609Z"
        transform="translate(-172.88211 -125.26949)" fill="#6c63ff" />
    <path
        d="M362.52806,536.06315c3.65967-2.48,6.98-5.04,9.77979-7.36035-1.00977-14.93994-23.02-46.21973-31.93995-46.21973C340.3679,482.48307,338.258,530.693,362.52806,536.06315Z"
        transform="translate(-172.88211 -125.26949)" opacity="0.2" />
    <path
        d="M407.83194,575.30031a10.05578,10.05578,0,0,1-5.277-14.48823l-23.35887-27.04334,18.41436-2.39689,19.35886,26.04829a10.11027,10.11027,0,0,1-9.13733,17.88017Z"
        transform="translate(-172.88211 -125.26949)" fill="#a0616a" />
    <path
        d="M342.526,444.008a16.3224,16.3224,0,0,1,3.02615.34653,12.49537,12.49537,0,0,1,8.91793,7.79987l28.87782,60.3287,31,33-15.5,12.5-49-44-19.576-53.28149A12.75366,12.75366,0,0,1,342.526,444.008Z"
        transform="translate(-172.88211 -125.26949)" fill="#6c63ff" />
    <path
        d="M324.245,435.45264c-3.49562-5.23231-6.25435-12.48756-2.40565-17.46591,3.79907-4.91416,11.29215-4.19018,17.11053-6.36466,8.104-3.02867,12.8041-12.5493,11.33825-21.07564S341.97777,374.952,333.824,372.06s-17.34839-1.95148-25.33312,1.37887c-9.82931,4.0997-18.26115,12.03028-21.79686,22.07625s-1.6456,22.10808,5.68929,29.82963c7.86381,8.27834,20.20556,10.48454,31.62276,10.35067"
        transform="translate(-172.88211 -125.26949)" fill="#2f2e41" />
    <path
        d="M285.67613,406.49292c-1.00062,5.591-7.13392,8.76649-12.77076,9.46443s-11.46786-.197-16.9553,1.269c-9.67415,2.58442-16.05479,11.88813-19.56679,21.26546s-5.06949,19.49826-9.63253,28.41158-13.41056,16.77135-23.38921,15.93771a25.732,25.732,0,0,0,48.39435-1.5693c3.66577-11.31688-.54222-24.81289,5.86843-34.83353,3.54741-5.54506,9.673-8.80673,15.57943-11.71293s12.10571-5.88925,16.0657-11.14761,4.75122-13.56285-.12875-17.98073"
        transform="translate(-172.88211 -125.26949)" fill="#2f2e41" />
    <path d="M174.07279,774.73051h320.294a1.19069,1.19069,0,0,0,0-2.38137h-320.294a1.19069,1.19069,0,0,0,0,2.38137Z"
        transform="translate(-172.88211 -125.26949)" fill="#3f3d56" />
    <circle cx="264.0495" cy="239.18898" r="14" fill="#e6e6e6" />
    <circle cx="709.0495" cy="116.18898" r="14" fill="#e6e6e6" />
    <circle cx="556.0495" cy="494.18898" r="14" fill="#e6e6e6" />
</svg>
    );
  }
}
